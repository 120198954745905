var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"px-2"},[_c('span',[_vm._v("รายงานยอดขาย")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.exportLoading,"disabled":_vm.loading},on:{"click":_vm.exportExcel}},[_vm._v("ออกรายงาน ")])],1),_c('DateFilters',{staticClass:"mb-2",attrs:{"default-status":1},on:{"onSendDate":_vm.addPayload}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataList,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"no-data-text":"ไม่มีข้อมูล !","hide-default-footer":"","loading-text":"กำลังโหลดข้อมูล...","footer-props":_vm.footer,"no-results-text":"ไม่มีข้อมูล !"},scopedSlots:_vm._u([{key:"item.create",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("sumdatetime")(item.create))+" ")])]}},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(_vm._s(item.product_name))])]}},{key:"item.order_detail_price",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(_vm._s(item.order_detail_price))])]}},{key:"item.order_detail_amount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(_vm._s(item.order_detail_amount))])]}},{key:"item.sum_price",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(_vm._s(item.sum_price))])]}},{key:"item.order_detail_discount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(_vm._s(item.order_detail_discount))])]}},{key:"item.sum_price_after_discount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(_vm._s(item.sum_price_after_discount))])]}},{key:"item.vat_value",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(_vm._s(item.vat_value))])]}},{key:"item.sum_price_after_vat",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(_vm._s(item.sum_price_after_vat))])]}},{key:"item.order_transportation_total",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(_vm._s(item.order_transportation_total))])]}},{key:"item.sum_price_after_transportation",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.product_name ==='รวม'?'primary--text font-weight-bold':''},[_vm._v(_vm._s(item.sum_price_after_transportation))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }