import { onMounted, ref, watch } from "@vue/composition-api";
import footerDataTables from "@/fake-db/footerDataTables.json";
import { api } from "@/services/api";
import { removeComma } from "@/services/filters"
import rangeSelete from "@/fake-db/rangeSelete.json";

export default function useSale() {
    const textSearch = ref('')
    const data_range_select = ref([
        {
            "value": 1,
            "th": "รายวัน",
            "en": "daily"
        },
        {
            "value": 2,
            "th": "รายเดือน",
            "en": "Monthly"
        },
        {
            "value": 3,
            "th": "รายปี",
            "en": "Yearly"
        },
    ])
    const select_range = ref(1)
    const XLSX = require('xlsx')
    const headers = ref([
        {
            text: 'คลังสินค้า',
            value: 'store_name',
            width: '160',
        },
        {
            text: 'วันที่สร้างออเดอร์',
            value: 'create',
            width: '200',
        },
        {
            text: 'ออเดอร์',
            value: 'order_code',
            width: '130',
        },
        {
            text: 'รหัสสินค้า',
            value: 'product_code',
            width: '30',
        },
        {
            text: 'ชื่อสินค้า',
            value: 'product_name',
            width: '200',
        },
        {
            text: 'ราคา/ขาย',
            value: 'order_detail_price',
            width: '130',
            align: 'end',
        },
        {
            text: 'จำนวน',
            value: 'order_detail_amount',
            width: '130',
            align: 'end',
        },
        {
            text: 'ราคา',
            value: 'sum_price',
            width: '130',
            align: 'end',
        },
        {
            text: 'ส่วนลด',
            value: 'order_detail_discount',
            width: '130',
            align: 'end',
        },
        {
            text: 'ราคาหลังหักส่วนลด',
            value: 'sum_price_after_discount',
            width: '130',
            align: 'end',
        },
        {
            text: 'ภาษี/vat %',
            value: 'order_detail_vat',
            width: '130',
            align: 'end',
        },
        {
            text: 'ภาษี/vat',
            value: 'vat_value',
            width: '130',
            align: 'end',
        },
        {
            text: 'ราคารวมภาษี',
            value: 'sum_price_after_vat',
            width: '130',
            align: 'end',
        },
        {
            text: 'ค่าขนส่ง',
            value: 'order_transportation_total',
            width: '130',
            align: 'end',
        },
        {
            text: 'ราคาสุทธิ',
            value: 'sum_price_after_transportation',
            width: '130',
            align: 'end',
        },
        {
            text: 'สกุลเงิน',
            value: 'currency_code',
            width: '130',
            align: 'end',
        },
    ])
    const itemsPerPage = ref(-1)
    const status = ref('1')
    const dataList = ref([])
    const loading = ref(false)
    const isUpdateStatus = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const isAdd = ref(false)
    const isShowDetail = ref(false)
    const storeList = ref([])
    const storeSelection = ref('')
    const totalBalance = ref('')
    const exportLoading = ref(false)
    const moment = require('moment')
    const start = ref(moment(new Date()).format('YYYY-MM-DD'))
    const end = ref(moment(new Date()).format('YYYY-MM-DD'))

    const getProducts = () => {
        loading.value = true
        api.get({
            path: '/reportSummary',
            param: `?type=${ select_range.value }&start=${ start.value }&end=${ end.value }&currency_id=${ localStorage.getItem('currencyId') }`,
        }).then(({ data, total }) => {
            totalBalance.value = total
            dataList.value = data
            console.log('data: ', data)
            loading.value = false
        }).catch(error => {
            console.log('error :', error)
            loading.value = false
        })
    }

    const addPayload = (dateStart, dateEnd, type) => {
        select_range.value = type
        start.value = dateStart
        end.value = dateEnd
        getProducts()
    }

    const getStores = () => {
        api.get({
            path: '/manager/stores',
            param: `?search=${ textSearch.value }&active=1`,
        }).then(({ data }) => {
            storeList.value = data
            storeList.value.unshift({ store_id: '', store_name: 'ทั้งหมด' })
            storeSelection.value = data[0].store_id
            getProducts()
        }).catch(error => {
            console.log('error :', error)
        })
    }


    const exportExcel = async () => {
        exportLoading.value = true
        const fileName = `รายงานสินค้าคงเหลือ.xlsx`
        let Heading = []
        if (select_range.value == 1 || select_range.value == 4 || select_range.value == 5) {
            Heading = [
                [`รายงานยอดขาย รายวัน`],
                [
                    'คลังสินค้า',
                    'วันที่สร้างออเดอร์',
                    'ออเดอร์',
                    'รหัสสินค้า',
                    'ชื่อสินค้า',
                    'ราคา/ขาย',
                    'จำนวน',
                    'ราคา',
                    'ส่วนลด',
                    'ราคาหลังหักส่วนลด',
                    'ภาษี/vat %',
                    'ภาษี/vat',
                    'ราคารวมภาษี',
                    'ค่าขนส่ง',
                    'ราคาสุทธิ',
                    'สกุลเงิน',
                ],
            ]
        } else if (select_range.value == 2) {
            Heading = [
                [`รายงานยอดขาย รายเดือน`],
                [
                    'คลังสินค้า',
                    'วันที่สร้างออเดอร์',
                    'ออเดอร์',
                    'รหัสสินค้า',
                    'ชื่อสินค้า',
                    'ราคา/ขาย',
                    'จำนวน',
                    'ราคา',
                    'ส่วนลด',
                    'ราคาหลังหักส่วนลด',
                    'ภาษี/vat %',
                    'ภาษี/vat',
                    'ราคาสุทธิ',
                    'สกุลเงิน',
                ],
            ]
        } else {
            Heading = [
                [`รายงานยอดขาย รายปี`],
                [
                    'คลังสินค้า',
                    'เดือน',
                    'ราคาสุทธิ',
                    'ภาษี   ',
                ],
            ]


        }

        const ws = XLSX.utils.aoa_to_sheet(Heading)

        const dataExport = await removeComma(JSON.parse(JSON.stringify(dataList.value)))
        XLSX.utils.sheet_add_json(ws, dataExport, {
            header: select_range.value == 1 || select_range.value == 4 || select_range.value == 5 ? [
                'store_name',
                'create',
                'order_code',
                'product_code',
                'product_name',
                'order_detail_price',
                'order_detail_amount',
                'sum_price',
                'order_detail_discount',
                'sum_price_after_discount',
                'order_detail_vat',
                'vat_value',
                'sum_price_after_vat',
                'order_transportation_total',
                'sum_price_after_transportation',
                'currency_code',
            ] : select_range.value == 2 ? [
                'store_name',
                'create',
                'order_code',
                'product_code',
                'product_name',
                'order_detail_price',
                'order_detail_amount',
                'sum_price',
                'order_detail_discount',
                'sum_price_after_discount',
                'order_detail_vat',
                'vat_value',
                'sum_price_after_vat',
                'currency_code',

            ] : [
                'store_name',
                'month_label',
                'total',
                'vat',
            ],
            skipHeader: true,
            origin: -1,
        })
        const wb = XLSX.utils.book_new()
        const merge = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
        ]
        const wscols = [
            { wch: 6 },
            { wch: 10 },
            { wch: 26 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
        ]
        ws['!merges'] = merge
        ws['!cols'] = wscols
        XLSX.utils.book_append_sheet(
            wb,
            ws,
            `รายงานยอดขาย`,
        )

        XLSX.writeFile(wb, fileName)
        setTimeout(() => {
            exportLoading.value = false
        }, 1500)
    }

    onMounted(() => {
        getStores()
    })

    watch(select_range, (val) => {
        if (select_range.value === 1) {
            headers.value = [
                {
                    text: 'คลังสินค้า',
                    value: 'store_name',
                    width: '160',
                },
                {
                    text: 'วันที่สร้างออเดอร์',
                    value: 'create',
                    width: '200',
                },
                {
                    text: 'ออเดอร์',
                    value: 'order_code',
                    width: '130',
                },
                {
                    text: 'รหัสสินค้า',
                    value: 'product_code',
                    width: '30',
                },
                {
                    text: 'ชื่อสินค้า',
                    value: 'product_name',
                    width: '200',
                },
                {
                    text: 'ราคา/ขาย',
                    value: 'order_detail_price',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'จำนวน',
                    value: 'order_detail_amount',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ราคา',
                    value: 'sum_price',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ส่วนลด',
                    value: 'order_detail_discount',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ราคาหลังหักส่วนลด',
                    value: 'sum_price_after_discount',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ภาษี/vat %',
                    value: 'order_detail_vat',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ภาษี/vat',
                    value: 'vat_value',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ราคารวมภาษี',
                    value: 'sum_price_after_vat',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ค่าขนส่ง',
                    value: 'order_transportation_total',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ราคาสุทธิ',
                    value: 'sum_price_after_transportation',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'สกุลเงิน',
                    value: 'currency_code',
                    width: '130',
                    align: 'end',
                },
            ]
        } else if (select_range.value === 2) {
            headers.value = [
                {
                    text: 'คลังสินค้า',
                    value: 'store_name',
                    width: '160',
                },
                {
                    text: 'วันที่สร้างออเดอร์',
                    value: 'create',
                    width: '200',
                },
                {
                    text: 'ออเดอร์',
                    value: 'order_code',
                    width: '130',
                },
                {
                    text: 'รหัสสินค้า',
                    value: 'product_code',
                    width: '30',
                },
                {
                    text: 'ชื่อสินค้า',
                    value: 'product_name',
                    width: '200',
                },
                {
                    text: 'ราคา/ขาย',
                    value: 'order_detail_price',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'จำนวน',
                    value: 'order_detail_amount',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ราคา',
                    value: 'sum_price',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ส่วนลด',
                    value: 'order_detail_discount',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ราคาหลังหักส่วนลด',
                    value: 'sum_price_after_discount',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ภาษี/vat %',
                    value: 'order_detail_vat',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ภาษี/vat',
                    value: 'vat_value',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ราคาสุทธิ',
                    value: 'sum_price_after_vat',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'สกุลเงิน',
                    value: 'currency_code',
                    width: '130',
                    align: 'end',
                },
            ]

        } else if (select_range.value === 3) {
            headers.value = [
                {
                    text: 'คลังสินค้า',
                    value: 'store_name',
                    width: '160',
                },
                {
                    text: 'เดือน',
                    value: 'month_label',
                    width: '160',
                },
                {
                    text: 'ราคาสุทธิ',
                    value: 'total',
                    align: 'end',
                    width: '160',
                },
                {
                    text: 'ภาษี',
                    value: 'vat',
                    align: 'end',
                    width: '160',
                },

            ]

        } else {
            headers.value = [
                {
                    text: 'คลังสินค้า',
                    value: 'store_name',
                    width: '160',
                },
                {
                    text: 'วันที่สร้างออเดอร์',
                    value: 'create',
                    width: '200',
                },
                {
                    text: 'ออเดอร์',
                    value: 'order_code',
                    width: '130',
                },
                {
                    text: 'รหัสสินค้า',
                    value: 'product_code',
                    width: '30',
                },
                {
                    text: 'ชื่อสินค้า',
                    value: 'product_name',
                    width: '200',
                },
                {
                    text: 'ราคา/ขาย',
                    value: 'order_detail_price',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'จำนวน',
                    value: 'order_detail_amount',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ราคา',
                    value: 'sum_price',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ส่วนลด',
                    value: 'order_detail_discount',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ราคาหลังหักส่วนลด',
                    value: 'sum_price_after_discount',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ภาษี/vat %',
                    value: 'order_detail_vat',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ภาษี/vat',
                    value: 'vat_value',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ราคารวมภาษี',
                    value: 'sum_price_after_vat',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ค่าขนส่ง',
                    value: 'order_transportation_total',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'ราคาสุทธิ',
                    value: 'sum_price_after_transportation',
                    width: '130',
                    align: 'end',
                },
                {
                    text: 'สกุลเงิน',
                    value: 'currency_code',
                    width: '130',
                    align: 'end',
                },
            ]
        }
    })


    return {
        textSearch,
        headers,
        itemsPerPage,
        isAdd,
        isShowDetail,
        isEdit,
        dataEdit,
        footer,
        isUpdateStatus,
        status,
        dataList,
        loading,
        storeList,
        storeSelection,
        exportLoading,
        data_range_select,
        select_range,
        addPayload,
        exportExcel,
        getProducts,
    }
}