<template>
    <div>
        <v-card>
            <v-card-title class="px-2">
                <span>รายงานยอดขาย</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="exportExcel" :loading="exportLoading" :disabled="loading">ออกรายงาน
                </v-btn>
            </v-card-title>
            <DateFilters :default-status="1" @onSendDate='addPayload' class="mb-2"/>
            <v-data-table
                    :headers='headers'
                    :items='dataList'
                    :items-per-page='itemsPerPage'
                    :loading='loading'
                    no-data-text='ไม่มีข้อมูล !'
                    hide-default-footer
                    loading-text='กำลังโหลดข้อมูล...'
                    :footer-props='footer'
                    no-results-text='ไม่มีข้อมูล !'
                    class='elevation-1'
            >
                <template #[`item.create`]='{ item }'>
                    <span>{{ item.create | sumdatetime }} </span>
                </template>
                <template #[`item.product_name`]='{ item }'>
                    <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">{{ item.product_name
                        }}</span>
                </template>
                <template #[`item.order_detail_price`]='{ item }'>
                    <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">{{ item.order_detail_price
                        }}</span>
                </template>
                <template #[`item.order_detail_amount`]='{ item }'>
                    <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">{{ item.order_detail_amount
                        }}</span>
                </template>
                <template #[`item.sum_price`]='{ item }'>
                    <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">{{ item.sum_price
                        }}</span>
                </template>
                <template #[`item.order_detail_discount`]='{ item }'>
                    <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">{{ item.order_detail_discount
                        }}</span>
                </template>
                <template #[`item.sum_price_after_discount`]='{ item }'>
                    <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">{{ item.sum_price_after_discount
                        }}</span>
                </template>
                <template #[`item.vat_value`]='{ item }'>
                    <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">{{ item.vat_value
                        }}</span>
                </template>
                <template #[`item.sum_price_after_vat`]='{ item }'>
                    <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">{{ item.sum_price_after_vat
                        }}</span>
                </template>
                <template #[`item.order_transportation_total`]='{ item }'>
                    <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">{{ item.order_transportation_total
                        }}</span>
                </template>
                <template #[`item.sum_price_after_transportation`]='{ item }'>
                    <span :class="item.product_name ==='รวม'?'primary--text font-weight-bold':''">{{ item.sum_price_after_transportation
                        }}</span>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import useSale from "@/views/report/useSale";
import CurrentCurrency from "@/components/CurrentCurrency";
import DateFilters from "@/components/DateFilters";
import { sumdatetime } from "@/services/filters";

export default {
    props: {},
    components: { CurrentCurrency, DateFilters },
    filters: {
        sumdatetime
    },
    setup(_, { root, emit }) {
        return { ...useSale() }
    },

}
</script>

<style scoped>

</style>
